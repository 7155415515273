import { useFlags } from 'flagsmith/react';
import html2canvas from 'html2canvas';

import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { formatCurrency, normalizeCurrency } from '@hl-portals/helpers';

import { useScreenSize } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { getEquityUnlockRange } from '../../../helpers';
import { MIN_HOME_VALUE } from '../../../metadata/constants';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

type AssumptionProps = {
  label: string;
  value: string;
  highlight?: string;
};

const Assumption = ({ label, value, highlight }: AssumptionProps) => (
  <Box flexDirection="column" gap="8px">
    <Box justifyContent="space-between">
      <Paragraph>{label}</Paragraph>
      <Paragraph textAlign="right">
        {value}
        {highlight && '*'}
      </Paragraph>
    </Box>
    {highlight && (
      <Alert variant="warning" noIcon>
        {highlight}
      </Alert>
    )}
  </Box>
);

export const EquityCalculation = () => {
  const {
    'equity-lead-submission-flow-phase-2': { enabled: isLeadSubmissionPhase2 },
  } = useFlags(['equity-lead-submission-flow-phase-2']);
  const [downloading, setDownloading] = useState(false);

  const { watch, setValue } = useFormContext();
  const { onFetchTitleFlex, onUpdateSnapshot } = useLeadSubmissionContext();
  const { isMobile } = useScreenSize();

  const {
    equity_unlock_result,
    progress_banner_dismissed,
    loan_officer_email,
    agent_email,
    full_address,
    liens_total,
    estimated_home_value,
  } = watch();

  const { low_end_range, high_end_range } = equity_unlock_result;
  const range = getEquityUnlockRange(low_end_range, high_end_range);

  const hasMinHomeValue =
    normalizeCurrency(estimated_home_value) < MIN_HOME_VALUE;

  const downloadImage = async () => {
    setDownloading(true);

    const elementId = 'equity-unlock-downloadable';
    const element = document.getElementById(elementId);

    html2canvas(element, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById(elementId).style.display = 'block';
      },
    }).then((canvas) => {
      const link = canvas.toDataURL('image/png', 1.0);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = link;
      a.download = 'estimated_equity_unlock_range.png';
      a.click();

      setDownloading(false);
    });
  };

  // TODO: after flag deprecation, make titleFlex call permanent
  const handleClick = () => {
    if (isLeadSubmissionPhase2) {
      onFetchTitleFlex();
    } else {
      onUpdateSnapshot();
    }
  };

  return (
    <>
      <Content>
        <Container title="Equity Unlock Amount">
          {!progress_banner_dismissed && (
            <Alert
              icon="checkCircle"
              iconSize={16}
              variant="success"
              mb="32px"
              onDismiss={() => setValue('progress_banner_dismissed', true)}
            >
              Your progress is saved. Continue your submission now, or resume
              later using the link we emailed you at{' '}
              {loan_officer_email || agent_email}.
            </Alert>
          )}

          <Box id="equity-unlock-downloadable" flexDirection="column">
            <Box
              p="40px 32px 24px 32px"
              flexDirection="column"
              gap="8px"
              border="1px solid #D0ECFF"
              borderRadius="8px 8px 0px 0px"
              bgcolor="#E9F6FF"
            >
              <Paragraph variant="text-xsmall" letterSpacing="1px">
                EQUITY UNLOCK RANGE:
              </Paragraph>

              <Paragraph variant={isMobile ? 'heading-4' : 'heading-2'}>
                {range}
              </Paragraph>

              {range === '$0' ? (
                <Paragraph variant="text-small" color="#72757D">
                  Our initial review suggests that we may not be able to unlock
                  equity from the home. However, your client may still be
                  eligible for other program benefits.
                </Paragraph>
              ) : (
                <Paragraph variant="text-small" color="#72757D">
                  Equity available for your next home purchase, already
                  accounting for selling and program costs.
                </Paragraph>
              )}
            </Box>

            <Box
              mb="16px"
              p="24px 24px 32px 24px"
              flexDirection="column"
              gap="16px"
              border="1px solid #EEF0F6"
              borderRadius="0px 0px 8px 8px"
            >
              <Paragraph variant="text-small" fontWeight="600">
                Assumptions:
              </Paragraph>
              <Assumption label="Departing Residence" value={full_address} />
              <Assumption
                label="Lien Balances"
                value={formatCurrency({ value: liens_total })}
              />
              <Assumption
                label="Home Value"
                value={formatCurrency({ value: estimated_home_value })}
                highlight={
                  hasMinHomeValue &&
                  '*If the final sale price is under $375,000, the program fee will be $9,000.'
                }
              />
            </Box>
          </Box>

          <Box
            mb={{ xs: '24px', md: '40px' }}
            flexDirection="column"
            gap="16px"
          >
            <Paragraph variant="text-xsmall" color="#8E929C">
              This equity unlock estimate is based on the numbers provided and
              our initial review. It is not a commitment to lend or a formal
              approval. Final figures will be given after further review and
              additional information. Minimums may apply.
            </Paragraph>
            <Button
              onClick={downloadImage}
              iconRight={<Icon type="downloadLine" />}
              color="tertiary"
              variant="outlined"
              size="large"
              isLoading={downloading}
              disabled={downloading}
              width="100%"
            >
              Download Range
            </Button>
          </Box>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleClick()} />
      </Footer>
    </>
  );
};
