import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const AddAssistantInfo = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const {
    watch,
    resetField,
    formState: { errors },
  } = useFormContext();

  const {
    assistant_name,
    assistant_email,
    assistant_phone,
    save_assistant_info,
  } = watch();

  const handleNext = () => {
    onUpdateSnapshot({
      assistant_name,
      assistant_email,
      assistant_phone,
      save_assistant_info,
    });
  };

  const fieldHasError = [
    errors?.assistant_name?.message,
    errors?.assistant_email?.message,
  ].some((errorMessage) => typeof errorMessage === 'string');

  const areReqFieldsEmpty = !assistant_name || !assistant_email;

  const disabled = fieldHasError || areReqFieldsEmpty;

  return (
    <>
      <Content>
        <Container
          title="Add an Assistant or Processor"
          subtitle="(Optional) You can also add it after completing the client’s application."
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Inputs.Text
                autofocus={areReqFieldsEmpty}
                name="assistant_name"
                label="Full name"
                placeholder="Enter agent’s full name"
                onClear={() => resetField('assistant_name')}
                mode="onBlur"
              />
              <Inputs.Email
                name="assistant_email"
                label="Email Address"
                placeholder="assistant@example.com"
                onClear={() => resetField('assistant_email')}
                mode="onBlur"
              />
              <Inputs.Phone
                name="assistant_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                optional
              />
              <Inputs.Checkbox name="save_assistant_agent_info">
                <Paragraph variant="text" textAlign="left">
                  Save team member for future transactions
                </Paragraph>
              </Inputs.Checkbox>
            </Box>
          </Box>
          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            We will include the team member in email communications so they can
            assist with the transaction.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disabled} />
      </Footer>
    </>
  );
};
