import styled, { css } from 'styled-components';

import { PropsWithChildren } from 'react';

import {
  Box,
  BoxTypes,
  Icon,
  IconTypeProp,
  Paragraph,
  Spinner,
} from '@hl-portals/ui';

type JustifyContent = 'flex-start' | 'center' | 'flex-end';

const ContainerStyles = styled(Box)<{
  isActive?: boolean;
  justifyContent?: JustifyContent;
}>`
  ${({ isActive, justifyContent }) => css`
    width: 100%;
    padding: 24px 32px;
    flex: 1;
    align-items: flex-start;
    justify-content: ${justifyContent ?? 'flex-start'};
    gap: 16px;
    border: 1px solid ${isActive ? '#46B6FF' : '#DBDFE6'};
    border-radius: 12px;
    background-color: ${isActive ? '#F2F9FE' : 'white'};
    cursor: pointer;

    &:hover {
      border: 1px solid #46b6ff;
      background-color: #f2f9fe;
    }

    .marker {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #dbdfe6;
      border-radius: 50%;
      background-color: white;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        transform: scale(0, 0);
        border-radius: 50%;
        background-color: #46b6ff;
        transition: all 200ms easy-in-out;
      }
    }

    .marker--active {
      border: 2px solid #46b6ff;

      &::before {
        transform: scale(1, 1);
      }
    }
  `}
`;

type CardRadioProps = PropsWithChildren<
  {
    title: string;
    subtitle?: string;
    icon?: IconTypeProp;
    isLoading?: boolean;
    isActive?: boolean;
    showMarker?: boolean;
    justifyContent?: string;
    onClick: () => void;
  } & BoxTypes
>;

export const CardRadio = ({
  title,
  subtitle,
  icon,
  isLoading,
  isActive,
  showMarker,
  justifyContent,
  ...props
}: CardRadioProps) => {
  if (isLoading) {
    return (
      <ContainerStyles justifyContent={justifyContent}>
        <Box justifyContent="center" alignItems="center">
          <Spinner xl />
        </Box>
      </ContainerStyles>
    );
  }

  return (
    <ContainerStyles isActive={isActive} {...props}>
      {showMarker && (
        <div className={`marker ${isActive ? 'marker--active' : ''}`} />
      )}
      <Box flex="1" flexDirection="column">
        <Paragraph fontWeight="bold">{title}</Paragraph>
        {subtitle && <Paragraph color="#72757D">{subtitle}</Paragraph>}
      </Box>
      {icon && (
        <Box width="32px" height="32px" flex="0 0 32px">
          <Icon
            size={32}
            type={icon}
            color={isActive ? '#46B6FF' : '#273653'}
            fill={isActive ? '#46B6FF' : '#273653'}
          />
        </Box>
      )}
    </ContainerStyles>
  );
};
