import { BackButton } from 'apps/equity-app/modules/bbys/components/uc-questionnaire/buttons';

import { useFormContext } from 'react-hook-form';

import { Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { Container, Content, Footer, NextButton } from '../../layout';

export const FinancialInformation = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const { watch } = useFormContext();

  const { target_equity_unlock } = watch();

  return (
    <>
      <Content>
        <Container
          title="What is your client’s target equity unlock amount?"
          subtitle="This is the amount needed for the Equity Unlock loan"
        >
          <Inputs.Currency name="target_equity_unlock" mb="12px" />
          <Paragraph color="#8E929C">
            If your client does not need the equity loan enter 0
          </Paragraph>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot()}
          disabled={!target_equity_unlock}
        />
      </Footer>
    </>
  );
};
