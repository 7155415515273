import { useFormContext } from 'react-hook-form';

import { Box } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { TIMELINE_OPTIONS, TimelineOptions } from '../../../metadata/constants';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const NewHomePurchaseTimeline = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { new_home_purchase_timeline } = watch();

  const handleSelect = (selection: TimelineOptions) => {
    setValue('new_home_purchase_timeline', selection);
    onUpdateSnapshot({ new_home_purchase_timeline: selection });
  };

  return (
    <>
      <Content>
        <Container title="When is your client planning to buy their new home?">
          <Box mb="20px" flexDirection="column" gap="16px">
            {TIMELINE_OPTIONS.map(({ type, label }) => (
              <CardRadio
                key={label}
                title={label}
                onClick={() => handleSelect(type)}
                isActive={new_home_purchase_timeline === type}
              />
            ))}
          </Box>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() => onUpdateSnapshot({ new_home_purchase_timeline })}
          disabled={new_home_purchase_timeline === undefined}
        />
      </Footer>
    </>
  );
};
