import * as Sentry from '@sentry/nextjs';

import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Alert,
  Box,
  Icon,
  IndentedText,
  Match,
  MultiColorIcon,
  Paragraph,
  useMultiStepContext,
} from '@hl-portals/ui';

import {
  AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS,
  AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_DENIALS_LABELS,
  AUTOMATED_BBYS_PROPERTY_WARNING_CHECKS_LABELS,
  useScreenSize,
} from '@hl-portals/hooks';

import { STEPS } from '../../metadata/constants';
import { FlowError } from '../../metadata/types';
import { ResultHeader } from '../layout';

type LinkProps = PropsWithChildren<{
  href: string;
  noIcon?: boolean;
}>;

const Link = ({ children, href, noIcon }: LinkProps) => (
  <Box as="a" display="inline-flex" href={href} gap="8px" color="#1192E5">
    {children}
    {!noIcon && <Icon type="externalLink" fill="#1192E5" />}
  </Box>
);

const SupportContactAlert = () => (
  <Alert>
    If this information is not accurate email us to discuss your situation in
    detail at{' '}
    <Link noIcon href="mailto:transactionsupport@homelight.com">
      transactionsupport@homelight.com
    </Link>
  </Alert>
);

const InvalidPropertyContent = () => {
  const { watch } = useFormContext();

  const {
    property_state,
    property_postal_code,
    property_eligibility_issues,
    property_eligibility_warnings,
    property_eligibility_denials,
  } = watch();

  const issues = property_eligibility_issues.map(
    (issue: string) => AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS[issue]
  );

  const warnings = property_eligibility_warnings.map((warning: string) => {
    const [, label] = AUTOMATED_BBYS_PROPERTY_WARNING_CHECKS_LABELS[warning];
    return label;
  });

  const blockers = [
    'You’ve already submitted this property for Buy Before You Sell. Please check your email for the confirmation and next steps.',
  ];

  const denials = property_eligibility_denials.map((denial: string) => {
    const label = AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_DENIALS_LABELS[denial];

    if (denial === 'ineligible_state_check') {
      return label(property_state);
    }
    if (denial === 'ineligible_zip_check') {
      return label(property_postal_code);
    }
    return label();
  });

  const errors = [...issues, ...warnings, ...blockers, ...denials];

  return (
    <>
      <Box mb="12px" flexDirection="column" gap="20px">
        {errors.map((error, i) => (
          <IndentedText key={i} indentation="8px" lineHeight="160%">
            {error}
          </IndentedText>
        ))}
      </Box>

      <SupportContactAlert />
    </>
  );
};

const InvalidStateContent = () => {
  const { watch } = useFormContext();

  const state = watch('property_state');
  const email = watch('loan_officer_email') || watch('agent_email');

  return (
    <>
      <IndentedText indentation="8px" mb="32px" lineHeight="160%">
        HomeLight’s Buy Before You Sell program is currently not available in{' '}
        <Paragraph as="span" fontWeight="bold">
          {state}
        </Paragraph>
      </IndentedText>
      <Paragraph lineHeight="160%">
        We will notify you at{' '}
        <Paragraph as="span" fontWeight="bold">
          {email}
        </Paragraph>{' '}
        when Buy Before You Sell program is available in your area!
      </Paragraph>
    </>
  );
};

const InvalidEligibilityCheck = () => {
  return (
    <>
      <Box mb="12px" flexDirection="column" gap="20px">
        <IndentedText indentation="8px" lineHeight="160%">
          The program is not available for properties located in age-restricted
          communities
        </IndentedText>
      </Box>

      <SupportContactAlert />
    </>
  );
};

const ReverseMortgageContent = () => {
  return (
    <>
      <IndentedText indentation="8px" mb="32px" lineHeight="160%">
        The property has a reverse mortgage
      </IndentedText>
      <SupportContactAlert />
    </>
  );
};

type FlowErrorViewProps = {
  error: FlowError;
  onGoBack: () => void;
};

export const FlowErrorView = ({ error, onGoBack }: FlowErrorViewProps) => {
  const { isDesktop } = useScreenSize();
  const { watch } = useFormContext();
  const { goToStep } = useMultiStepContext();

  const fullAddress = watch('full_address');

  const renderImage = () => {
    if (!isDesktop) return <></>;

    return (
      <Match<FlowError>
        value={error}
        with={{
          property_not_eligible: () => <MultiColorIcon.House4 />,
          state_not_available: () => <MultiColorIcon.House3 />,
          reverse_mortgage: () => <MultiColorIcon.House5 />,
          dr_in_age_restricted_community: () => <MultiColorIcon.House6 />,
        }}
      />
    );
  };

  const onBackClick = () => {
    switch (error) {
      case 'property_not_eligible':
        goToStep(STEPS.HOME_VALUE);
        break;
      case 'state_not_available':
        goToStep(STEPS.DEPARTING_RESIDENCE);
        break;
      case 'reverse_mortgage':
        goToStep(STEPS.LIENS_SELECTION);
        break;
      case 'dr_in_age_restricted_community':
        goToStep(STEPS.AGE_RESTRICTED_COMMUNITY);
        break;
      default:
        Sentry.captureException(new Error(`Invalid error type: ${error}`));
    }
    onGoBack();
  };

  return (
    <Box width="100%" height="100vh" flexDirection="column">
      <ResultHeader />

      <Box
        p={{ xs: '32px 16px 64px 16px', md: '0' }}
        justifyContent="center"
        bgcolor="#F2F9FE"
      >
        <Box
          width={{ xs: '100%', md: '1048px' }}
          justifyContent="space-between"
          alignItems="center"
          gap="80px"
        >
          <Box maxWidth="600px" flexDirection="column" gap="12px">
            <Box
              alignSelf="flex-start"
              gap="4px"
              alignItems="center"
              fontWeight="bold"
              color="#273653"
              cursor="pointer"
              onClick={onBackClick}
            >
              <Icon type="angleLeft" />
              Back
            </Box>

            <Paragraph variant="heading-4">
              {error === 'state_not_available'
                ? 'Sorry, the program is not available in your area'
                : 'Sorry, the property is not eligible'}
            </Paragraph>
            <Paragraph maxWidth="520px" lineHeight="160%" color="#55585E">
              The departing residence {fullAddress} doesn’t meet HomeLight’s Buy
              Before You Sell eligibility criteria
            </Paragraph>
          </Box>

          {renderImage()}
        </Box>
      </Box>

      <Box
        width={{ xs: '100%', md: '1048px' }}
        p={{ xs: '16px', md: '32px 0px' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignSelf="center"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={{ xs: '40px', md: '80px' }}
      >
        <Box
          maxWidth={{ xs: '100%', md: '620px' }}
          mt="-64px"
          p="32px 24px"
          flexDirection="column"
          bgcolor="white"
          borderRadius="12px"
          boxShadow="0px 14px 48px rgba(108, 129, 171, 0.2)"
        >
          <Paragraph mb="12px" fontWeight="bold">
            Why is the property not eligible?
          </Paragraph>
          <Match<FlowError>
            value={error}
            with={{
              property_not_eligible: () => <InvalidPropertyContent />,
              state_not_available: () => <InvalidStateContent />,
              reverse_mortgage: () => <ReverseMortgageContent />,
              dr_in_age_restricted_community: () => <InvalidEligibilityCheck />,
            }}
          />
        </Box>

        <Box
          width={{ xs: '100%', md: '400px' }}
          flexDirection="column"
          border="1px solid #DBDFE6"
          borderRadius="16px"
        >
          <Box p="24px 32px" borderBottom="1px solid #DBDFE6">
            <Paragraph>Need help?</Paragraph>
          </Box>
          <Box p="32px" flexDirection="column" gap="40px">
            <Link href="https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements">
              Eligibility Requirements
            </Link>
            <Link href="https://help.homelight.com/lender-frequently-asked-questions">
              FAQs
            </Link>
            <Link href="mailto:lenders@homelight.com">Contact us</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
