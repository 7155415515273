import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph, SlideDown } from '@hl-portals/ui';

import { useModal } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { AdditionalTitleHolderModal } from './modal';

export const AddClientInformation = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const {
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();
  const { openModal, closeModal, isModalOpened } = useModal();
  const [renderModal, setRenderModal] = useState(false);

  const {
    add_client,
    add_additional_clients_later,
    primary_client_name,
    primary_client_email,
    primary_client_phone,
    additional_client_name,
    additional_client_email,
    additional_client_phone,
    trust,
  } = watch();

  const buttonText = !add_client ? 'Add client' : 'Remove';
  const arePrimaryFieldsEmpty =
    !primary_client_name || !primary_client_email || !primary_client_phone;

  const areAdditionalFieldsEmpty =
    !additional_client_name ||
    !additional_client_email ||
    !additional_client_phone;

  const fieldHasError = [
    errors?.primary_client_name?.message,
    errors?.primary_client_email?.message,
    errors?.primary_client_phone?.message,
    errors?.additional_client_name?.message,
    errors?.additional_client_email?.message,
    errors?.additional_client_phone?.message,
  ].some((errorMessage) => typeof errorMessage === 'string');

  const hasCoTitleHolder = trust?.owner_names.length >= 2;

  // only disable if field has error or if primary fields are empty
  const disabled = fieldHasError || arePrimaryFieldsEmpty;

  // supplying additional title holder data (secondary fields) is designed to be optional here
  const hasMissingTitleData =
    hasCoTitleHolder &&
    areAdditionalFieldsEmpty &&
    add_additional_clients_later === undefined;

  const goNext = () => {
    onUpdateSnapshot({
      add_additional_clients_later,
      // has_additional_client
      primary_client_name,
      primary_client_email,
      primary_client_phone,
      additional_client_name,
      additional_client_email,
      additional_client_phone,
    });
  };

  const handleNext = () => {
    if (hasMissingTitleData) {
      setRenderModal(true);
    } else {
      goNext();
    }
  };

  const handleClick = () => {
    if (!add_client) {
      setValue('add_client', true);
    } else {
      resetField('additional_client_name');
      resetField('additional_client_email');
      resetField('additional_client_phone');
      setValue('add_client', false);
    }
  };

  useEffect(() => {
    if (renderModal && !isModalOpened) {
      openModal(
        <AdditionalTitleHolderModal
          onClose={() => {
            setValue('add_additional_clients_later', true);
            closeModal();
            if (!arePrimaryFieldsEmpty && !fieldHasError) {
              goNext();
            }
          }}
          onConfirm={() => {
            setValue('add_client', true);
            setValue('add_additional_clients_later', false);
            closeModal();
          }}
        />
      );
    }
  }, [renderModal]);

  return (
    <>
      <Content>
        <Container
          title="Add client information"
          subtitle="This should include all title holders of the departing residence."
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Paragraph variant="heading-5">Client</Paragraph>
              <Inputs.Text
                name="primary_client_name"
                label="Full name"
                placeholder="Enter client’s full name"
                mode="onBlur"
              />
              <Inputs.Email
                name="primary_client_email"
                label="Email Address"
                placeholder="client1@example.com"
                mode="onBlur"
              />
              <Inputs.Phone
                name="primary_client_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                mode="onBlur"
              />
              <Box flexDirection="column">
                <Box
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={{ xs: '24px', md: '40px' }}
                >
                  <Paragraph
                    variant="heading-5"
                    display="inline-block"
                    visibility={!add_client ? 'hidden' : 'visible'}
                  >
                    Client 2
                  </Paragraph>
                  <Paragraph
                    display="inline-block"
                    color="#1192E5"
                    cursor="pointer"
                    onClick={() => handleClick()}
                  >
                    {buttonText}
                  </Paragraph>
                </Box>
                <SlideDown
                  display={add_client ? 'block' : 'none'}
                  gap="32px"
                  mb={{ xs: '24px', md: '40px' }}
                >
                  <Box flexDirection="column" gap="32px">
                    <Inputs.Text
                      autofocus={add_client}
                      name="additional_client_name"
                      label="Full Name"
                      placeholder="Enter client’s full name"
                      mode="onBlur"
                    />
                    <Inputs.Email
                      name="additional_client_email"
                      label="Email Address"
                      placeholder="client2@example.com"
                      mode="onBlur"
                    />
                    <Inputs.Phone
                      name="additional_client_phone"
                      label="Phone Number"
                      placeholder="555-555-5555"
                      mode="onBlur"
                    />
                  </Box>
                </SlideDown>
              </Box>
            </Box>
          </Box>
          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            We are unable to issue a program agreement without contact
            information for all title holders.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disabled} />
      </Footer>
    </>
  );
};
