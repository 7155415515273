import { z } from 'zod';

import { customZodTypes } from '@hl-portals/helpers';

import { Step } from '../constants';

const { phone, email, yesOrNo, fullName } = customZodTypes;

export const FORM_ERROR = {
  COMPANY_NAME: 'Enter a valid Company Name',
  AGENT_NAME: 'Enter a valid Agent Name',
  SALES_CONSULTANT_NAME: 'Enter a valid Sales Consultant Name',
  ADDRESS: 'Enter a valid Address',
  CITY: 'Enter a valid City',
  STATE: 'Enter a valid State',
  ZIP: 'Enter a valid Zipcode',
  CLIENT_NAME: 'Enter a valid Client Name',
  ADDITIONAL_CLIENT_NAME: 'Enter a valid Additional Client Name',
};

export const schema = z
  .object({
    // Global
    snapshot_uuid: z.string(),
    snapshot_id: z.string(),
    partner_slug: z.string(),
    flow: z.enum([
      'lenderSubmission',
      'agentSubmission',
      'externalLeadSubmitter',
      'lennarSubmission',
    ]),
    error: z.enum([
      'property_not_eligible',
      'state_not_available',
      'reverse_mortgage',
      'dr_in_age_restricted_community',
      null,
    ]),
    submitted_step: z.string(),

    // Lead Info
    current_flow: z.enum(['lender', 'agent']),
    lender_company_name: z
      .string()
      .min(1, { message: FORM_ERROR.COMPANY_NAME }),
    loan_officer_name: fullName(),
    loan_officer_email: email(),
    loan_officer_phone: phone(),
    agent_name: z.string().min(1, { message: FORM_ERROR.AGENT_NAME }),
    agent_email: email(),
    agent_phone: phone(),
    sales_consultant_name: z
      .string()
      .min(1, { message: FORM_ERROR.SALES_CONSULTANT_NAME }),
    sales_consultant_email: email(),
    sales_consultant_phone: phone(),
    external_escrow_officer_name: z.string(),
    external_escrow_officer_email: z.string(),
    division: z.string(),
    remember_me: z.boolean(),

    // Departing Residence
    full_address: z.string(),
    property_address: z.string().min(1, { message: FORM_ERROR.ADDRESS }),
    property_unit: z.string(),
    property_city: z.string().min(1, { message: FORM_ERROR.CITY }),
    property_state: z.string().min(1, { message: FORM_ERROR.STATE }),
    property_postal_code: z.string().min(1, { message: FORM_ERROR.ZIP }),
    property_uuid: z.string(),
    is_state_eligible: z.boolean(),

    // Home Value
    acknowledged: z.boolean(),
    estimated_home_value: z.string(),
    agent_valuation: z.string(),
    is_property_eligible: z.boolean(),
    property_eligibility_issues: z.array(z.string()),
    property_eligibility_warnings: z.array(z.string()),
    property_eligibility_blockers: z.array(z.string()),
    property_eligibility_denials: z.array(z.string()),
    equity_unlock_result: z.object({
      bbys_fee: z.number(),
      agent_fee: z.number(),
      estimated_selling_costs: z.number(),
      low_end_range: z.number(),
      high_end_range: z.number(),
      estimated_loan_payoff: z.number(),
      valid_equity: z.boolean(),
      fallback: z.boolean(),
    }),
    liens_present: z.boolean(),

    // Program Eligibility
    credit_score: z.boolean().optional(),
    no_va_fha_requirement: z.boolean().optional(),
    no_age_restricted_community: z.boolean().optional(),
    primary_residence: z.boolean().optional(),
    denied: z.boolean(),
    automated_bbys_eligibility_check: z.boolean().default(false),
    eligibility_result: z.object({
      eligibility_checks: z.object({}),
      eligibility_blockers: z.object({
        lead_exists: z.boolean(),
      }),
      warning_checks: z.object({
        low_equity_check: z.boolean(),
        rural_property_check: z.boolean(),
        listed_DOM_check: z.boolean(),
        unrealistic_value_check: z.boolean(),
      }),
      denial_checks: z.object({
        ineligible_state_check: z.boolean(),
        ineligible_zip_check: z.boolean(),
        zoning_description_check: z.boolean(),
        lot_size_check: z.boolean(),
        low_value_check: z.boolean(),
        not_enough_equity_check: z.boolean(),
        sq_footage_check: z.boolean(),
      }),
    }),

    // Liens
    is_home_owned: z.boolean().default(false),
    secondary_loans: z
      .array(
        z.object({
          type: z.string(),
          label: z.string(),
          balance: z.string(),
        })
      )
      .min(1),
    liens_total: z.number(),

    // Equity Calculation
    progress_banner_dismissed: z.boolean(),

    // Client Information
    has_additional_client: z.boolean(),
    add_additional_clients_later: z.boolean(),
    primary_client_name: z.string().min(1, { message: FORM_ERROR.CLIENT_NAME }),
    primary_client_email: email(),
    primary_client_phone: phone(),
    additional_client_name: z
      .string()
      .min(1, { message: FORM_ERROR.ADDITIONAL_CLIENT_NAME })
      .optional(),
    additional_client_email: email(),
    additional_client_phone: phone(),

    under_contract: yesOrNo.optional(),

    // Financial Information
    target_equity_unlock: z.string(),
    finance_type: z.enum(['mortgage', 'cash', 'unsure']),

    // Agent Information
    dr_agent_present: yesOrNo,

    // Loan Officer Information
    lo_present: yesOrNo,

    // Trust
    trust: z.object({
      owner_names: z.array(z.string()),
      dr_held_in_trust: z.boolean().nullable(),
    }),
    dr_held_in_trust_userselect: z.boolean().nullable().default(undefined),

    // Eligibility Check
    is_credit_score_above_620: z.boolean(),
    is_age_restricted: z.boolean(),
    is_moving_from_primary_residence: z.boolean(),
    financing: z.enum([
      'all_cash',
      'mortgage_conventional',
      'mortgage_fha',
      'mortgage_va',
      'other',
    ]),

    // Is in Escrow
    is_home_under_contract: z.boolean().default(undefined),
    new_property_close_of_escrow_date: z.string(),
    new_home_total_cash_to_close: z.number(),
    new_home_target_equity_unlock_amount: z.number(),
    // new_home_purchase_contract_link: z.string(),
    new_home_purchase_timeline: z.enum([
      '1 month',
      '2-3 months',
      '3+ months',
      'unsure',
    ]),
    client_target_equity_unlock_amount: z.number(),

    // Names on Title
    // title_holders: z.array(
    //   z.object({
    //     holder_name: z.string(),
    //     holder_email: z.string().email(),
    //     holder_phone: phone(),
    //   })
    // ),

    // Agent Info
    dr_agent_name: fullName(),
    dr_agent_email: email(),
    dr_agent_phone: phone(),
    save_dr_agent_info: z.boolean(),

    // Assistant Info
    assistant_name: fullName(),
    assistant_email: email(),
    assistant_phone: phone(),
    save_assistant_info: z.boolean(),

    // Review and Submit
    notes: z.string(),
    has_submitted: z.boolean(),
    token: z.string(),
    submit_response: z.object({
      agent_estimated_loan_payoff: z.string(),
      agent_fee: z.number(),
      agent_valuation: z.number(),
      bbys_fee: z.number(),
      closing_cost: z.number(),
      departing_property_full_address: z.string(),
      eligibility_checks: z.object({
        age_restricted_community_check: z.boolean(),
        credit_score_check: z.boolean(),
        primary_residence_check: z.boolean(),
        va_fha_requirement_check: z.boolean(),
      }),
      equity_unlock_threshold: z.number(),
      estimated_loan_payoff: z.number(),
      estimated_mortgage_balance: z.number(),
      fallback: z.boolean(),
      high_end_range: z.number(),
      id: z.string(),
      inspection_cost: z.number(),
      instant_approval_success: z.boolean(),
      lead_id: z.number(),
      lender_company: z.string(),
      lender_email: z.string(),
      lender_name: z.string(),
      lender_phone: z.string(),
      light_approval_success: z.boolean(),
      low_end_range: z.number(),
      photos_upload_task_id: z.string(),
      property_questionnaire_task_id: z.string(),
      property_type: z.string(),
      success: z.boolean(),
      target_unlock_amount: z.number(),
      token: z.string(),
      valid_equity: z.boolean(),
    }),
  })
  .partial();

export type InferedFormData = z.infer<typeof schema>;
export type FormData = Omit<InferedFormData, 'submitted_step'> & {
  submitted_step: Step;
};
export type FormKey = keyof FormData;
