import { useFlags } from 'flagsmith/react';

import { useFormContext } from 'react-hook-form';

import { Box, Checkbox, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  HOME_OWNED,
  LIENS,
  Lien,
  LienType,
  Liens,
} from '../../../metadata/constants';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const LiensSelection = () => {
  const {
    'equity-lead-submission-flow-phase-2': { enabled: isLeadSubmissionPhase2 },
  } = useFlags(['equity-lead-submission-flow-phase-2']);

  const { onUpdateSnapshot, onSetFlowError, onCheckPropertyEligibility } =
    useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const liens = (watch('secondary_loans') as Liens) || [];
  const isHomeOwnedChecked = watch('is_home_owned');
  const enableNext = liens.length !== 0 || isHomeOwnedChecked;

  const add = (lien: Lien) => {
    const newLiens = [...liens, lien];
    setValue('secondary_loans', newLiens);
  };

  const remove = (type: LienType) => {
    const newLiens = liens.filter((lien) => lien.type !== type);
    setValue('secondary_loans', newLiens);
  };

  const checkDisabled = (type: LienType) => {
    if (liens.length === 0 && !isHomeOwnedChecked) return false;

    const isHomeOwned = type === HOME_OWNED;

    if (isHomeOwnedChecked && !isHomeOwned) {
      return true;
    }
    if (!isHomeOwnedChecked && !isHomeOwned) {
      return false;
    }
    if (!isHomeOwnedChecked && isHomeOwned) {
      return true;
    }
  };

  const onClick = () => {
    const hasReverseMortgage = liens.some(
      (lien) => lien.type === 'reverse_mortgage'
    );

    if (hasReverseMortgage) {
      onSetFlowError('reverse_mortgage');
      return;
    }

    if (isHomeOwnedChecked && isLeadSubmissionPhase2) {
      onCheckPropertyEligibility();
    } else {
      onUpdateSnapshot();
    }
  };

  return (
    <>
      <Content>
        <Container title="Are there any outstanding liens on the departing residence?">
          <Paragraph mb="16px" color="#55585E">
            Select all that apply
          </Paragraph>

          <Box
            mb={{ xs: '24px', md: '40px' }}
            flexDirection="column"
            gap="16px"
          >
            {LIENS.map((option) => {
              const isDisabled = checkDisabled(option.type);
              const isHomeOwnedOption = option.type === HOME_OWNED;

              const isChecked =
                (isHomeOwnedOption && isHomeOwnedChecked) ||
                liens.some((lien) => lien.type === option.type);

              const onChange = () => {
                if (isChecked) {
                  remove(option.type);
                  if (isHomeOwnedOption) {
                    setValue('is_home_owned', false);
                  }
                  return;
                }
                if (isHomeOwnedOption) {
                  setValue('is_home_owned', true);
                  setValue('liens_total', 0);
                  return;
                }
                add(option);
              };

              return (
                <Box
                  key={option.type}
                  p="12px 8px"
                  gap="12px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor={isChecked ? '#46B6FF' : '#F5F6F9'}
                  bgcolor={isChecked ? '#F2F9FE' : '#F5F6F9'}
                >
                  <Checkbox
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={onChange}
                    label={option.label}
                    fullWidth
                  />
                </Box>
              );
            })}
          </Box>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={onClick} disabled={!enableNext} />
      </Footer>
    </>
  );
};
