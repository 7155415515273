import { STEPS, Step } from '../../metadata/constants';
import { FormData } from '../../metadata/forms';

export function getStepsToSkip(formData: FormData): Step[] {
  const {
    is_home_owned,
    current_flow,
    finance_type,
    trust,
    is_home_under_contract,
  } = formData;
  const commonSteps = [];

  if (is_home_owned) {
    commonSteps.push(STEPS.LIENS_BALANCES);
  }

  if (trust?.dr_held_in_trust) {
    commonSteps.push(STEPS.TRUST);
  }

  if (is_home_under_contract) {
    commonSteps.push(STEPS.NEW_HOME_PURCHASE_TIMELINE);
    commonSteps.push(STEPS.NEW_HOME_TARGET_EQUITY_AMT);
  }

  if (is_home_under_contract === false) {
    commonSteps.push(STEPS.NEW_HOME_CLOSING_DATE);
    commonSteps.push(STEPS.NEW_HOME_PURCHASE_FUNDS);
    commonSteps.push(STEPS.NEW_HOME_PURCHASE_CONTRACT);
  }

  if (current_flow === 'lender') {
    return [
      ...commonSteps,
      STEPS.LOAN_OFFICER_INFORMATION,
      STEPS.FINANCIAL_TYPE,
    ];
  }

  if (current_flow === 'agent') {
    if (finance_type === 'cash') {
      return [
        ...commonSteps,
        STEPS.FINANCIAL_INFORMATION,
        STEPS.AGENT_INFORMATION,
      ];
    }
    return [...commonSteps, STEPS.AGENT_INFORMATION];
  }

  return [];
}
