import * as Sentry from '@sentry/nextjs';

import { Box, Paragraph, Upload, useUploadContext } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const NewHomePurchaseContract = () => {
  const { onUpdateSnapshot, isLoading } = useLeadSubmissionContext();

  const upload = useUploadContext();

  const hasFailedFiles = upload.files.some((f) => f.status === 'error');

  const onSubmitClick = async () => {
    try {
      const { success } = await upload.submit();
      if (success) {
        onUpdateSnapshot();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const submitBtnProps = {
    disabled: hasFailedFiles,
    isLoading: upload.isLoading || isLoading,
    onClick: () => onSubmitClick(),
  };

  return (
    <>
      <Content>
        <Container
          title="Upload the new home purchase contract"
          subtitle="(Optional) You can also add it after completing the client’s application."
        >
          <Box mb="20px" flexDirection="column" gap="16px">
            <Upload.Drop
              display={{ xs: 'none', sm: 'block' }}
              maxFileSize="10"
            />
            <Box flexDirection="column" mt="4px">
              <Paragraph fontSize="16px" color="coolGray2">
                Your uploads
              </Paragraph>
              <Upload.List />
            </Box>
          </Box>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton {...submitBtnProps} />
      </Footer>
    </>
  );
};
