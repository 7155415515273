import { useFormContext } from 'react-hook-form';

import { Alert, Box, Inputs, Paragraph } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const AddAgentInfo = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();
  const {
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const { dr_agent_name, dr_agent_email, dr_agent_phone, save_dr_agent_info } =
    watch();

  const handleNext = () => {
    setValue('save_dr_agent_info', save_dr_agent_info);
    onUpdateSnapshot({
      dr_agent_name,
      dr_agent_email,
      dr_agent_phone,
      save_dr_agent_info,
    });
  };

  const fieldHasError = [
    errors?.dr_agent_name?.message,
    errors?.dr_agent_email?.message,
  ].some((errorMessage) => typeof errorMessage === 'string');

  const areReqFieldsEmpty = !dr_agent_name || !dr_agent_email;

  const disabled = fieldHasError || areReqFieldsEmpty;

  return (
    <>
      <Content>
        <Container
          title="Add a departing residence agent"
          subtitle="(Optional) You can also add it after completing the client’s application. "
        >
          <Box flex="1" flexDirection="column">
            <Box flexDirection="column" gap="32px">
              <Inputs.Text
                autofocus={areReqFieldsEmpty}
                name="dr_agent_name"
                label="Full name"
                placeholder="Enter agent’s full name"
                onClear={() => resetField('dr_agent_name')}
                mode="onBlur"
              />
              <Inputs.Email
                name="dr_agent_email"
                label="Email Address"
                placeholder="agent@example.com"
                onClear={() => resetField('dr_agent_email')}
                mode="onBlur"
              />
              <Inputs.Phone
                name="dr_agent_phone"
                label="Phone Number"
                placeholder="555-555-5555"
                optional
              />
              <Inputs.Checkbox name="save_dr_agent_info">
                <Paragraph variant="text" textAlign="left">
                  Save agent for future transactions
                </Paragraph>
              </Inputs.Checkbox>
            </Box>
          </Box>
          <Alert title="Why we ask this" mb={{ xs: '24px', md: '40px' }}>
            We require an agent to coordinate the listing and sale of the
            departing residence.
          </Alert>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleNext()} disabled={disabled} />
      </Footer>
    </>
  );
};
