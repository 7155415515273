import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

type ReviewEligibilityStatusModalProp = {
  title: React.ReactNode;
  description: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  renderFootnote?: boolean;
};

export const ReviewEligibilityStatusModal = ({
  title,
  description,
  onClose,
  onConfirm,
  renderFootnote = false,
}: ReviewEligibilityStatusModalProp) => {
  return (
    <Box
      maxWidth={{ xs: '100%', md: '520px' }}
      p="8px 16px"
      flexDirection="column"
    >
      <Box
        pb="16px"
        alignItems="center"
        gap="12px"
        borderBottom="1px solid #DBDFE6"
      >
        <Box
          width="40px"
          height="40px"
          justifyContent="center"
          alignItems="center"
          borderRadius="4px"
          bgcolor="#FFECD1"
        >
          <Icon type="triangleInfo" />
        </Box>
        <Paragraph variant="heading-4">Review eligibility status</Paragraph>
      </Box>

      <Box p="24px 4px" flexDirection="column" gap="24px">
        <Box
          maxHeight="300px"
          overflowY="auto"
          flexDirection="column"
          gap="24px"
        >
          <Box
            p="12px"
            flexDirection="column"
            flex="0 0 4px"
            borderRadius="8px 0px 0px 8px"
            bgcolor="#F8F8FB"
            borderLeft="4px solid #D9D9D9"
          >
            {title}
            {description}
          </Box>
        </Box>

        {renderFootnote && (
          <Paragraph>
            The client’s eligibility will be defined after we review the
            application.
          </Paragraph>
        )}

        <Box gap="16px" justifyContent="flex-end">
          <Button color="tertiary" size="large" onClick={onClose}>
            Cancel submission
          </Button>
          <Button size="large" onClick={onConfirm} isLoading={false}>
            Got it
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
