import { useFormContext } from 'react-hook-form';

import { Alert, Box } from '@hl-portals/ui';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { CardRadio } from '../../card-radio';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';

export const NewHomeUnderContract = () => {
  const { onUpdateSnapshot } = useLeadSubmissionContext();

  const { watch, setValue } = useFormContext();
  const { is_home_under_contract } = watch();

  const handleSelect = (type: boolean) => {
    setValue('is_home_under_contract', type);
    onUpdateSnapshot({ is_home_under_contract: type });
  };

  return (
    <>
      <Content>
        <Container title="Is your client under contract on a new home?">
          <Box flex="1" flexDirection="column">
            <Box mb="20px" flexDirection="column" gap="16px">
              <CardRadio
                title="Yes"
                onClick={() => handleSelect(true)}
                isActive={is_home_under_contract === true}
                justifyContent="center"
              />
              <CardRadio
                title="No"
                isActive={is_home_under_contract === false}
                onClick={() => handleSelect(false)}
                justifyContent="center"
              />
            </Box>
          </Box>
          <Alert
            variant="info"
            title="Why we ask this"
            mb={{ xs: '24px', md: '40px' }}
          >
            We use this information to determine the next steps and understand
            any deadlines related to the new home purchase.
          </Alert>
        </Container>
      </Content>
      <Footer>
        <BackButton />
        <NextButton
          onClick={() =>
            onUpdateSnapshot({ is_home_under_contract: is_home_under_contract })
          }
          disabled={is_home_under_contract === undefined}
        />
      </Footer>
    </>
  );
};
